.table-container {
    display: flex;
    flex-direction: column;
}

.table-row {
    display: flex;
    align-items: center;
    padding: 16px;
}

.table-row:hover {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #1C213D !important;
}

.table-row-light {
    background-color: #f5f5f5;
}

.table-row-dark {
    background-color: #eaeaea;
}

.table-header {
    font-weight: bold;
}

.table-cell {
    flex: 1;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}

.table-cell1 {
    flex: 1;
    text-align: center;
    font-weight: 500;
    color: #979FB1;
    font-size: 16px;
}

/* Adjust the styles below as needed */
.table-container {
    width: 300px;
    margin: 0 auto;
}

.table-row {
    border-bottom: none;
}

.table-row:last-child {
    border-bottom: none;
}

.table-header {
    background-color: #000000;
}