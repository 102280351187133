.winning-numbers-card {
    /* margin: 5px; */
    /* background: #0A0F1D;
    border: 3px solid rgba(0, 126, 255, .5294117647058824); */
    background: rgba(16, 24, 60, 0.66) !important;
    border-top: 1px solid #00B0F2 !important;
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48) !important;
    border-radius: 16px;
    /* border-radius: 22px !important; */
    /* border: none !important; */
}

.winning-numbers-headline {
    text-align: center;
}

.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th,
.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
    position: relative;
    padding: 16px;
    text-align: left !important;
    overflow-wrap: break-word;
}

.winner-result-table-alignment {
    max-width: 900px !important;
    min-width: 900px !important;
}

.claim-card-content-last-winning {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    /* margin-bottom: 4px; */
}

.winning-numbers-head {
    font-weight: 500;
    font-size: 16px;
}

.winning-numbers-set {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-pragraph {
    text-align: center;
    max-width: 250px;
    min-width: 250px;
}

.number {
    font-size: 19px;
    text-align: center;
    z-index: 999 !important;
    margin-left: -50px;
    font-weight: 700;
}

.avatar-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    margin: 3px;
}

.winner-header-align {
    justify-content: space-between
}

.wn-sub-text {
    margin-bottom: 12px;
    font-weight: 500;
    color: #979FB1;
    font-size: 16px;
    /* justify-content: space-between; */
}

.wn-sub-text2 {
    margin-bottom: 12px;
    color: #979FB1;
    font-size: 16px;
    font-weight: 500;
    margin-left: 260px;
    /* justify-content: space-between; */
}

@media (max-width: 768px) {
    .results-table-card {
        /* margin: 5px; */
        min-height: 280px;
        max-height: 280px;
    }

    .winner-result-table-alignment {
        /* max-width: 300px !important; */
        min-width: 300px !important;
    }

    .avatar-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin: 3px;
    }

    .number {
        font-size: 16px;
        text-align: center;
        z-index: 999 !important;
        margin-left: 0px;
        font-weight: 700;
    }

    .claim-card-content-last-winning {
        display: flex;
        align-items: flex-start;
        /* justify-content: space-; */
        /* margin-bottom: 4px; */
    }

    .wn-sub-text {
        margin-bottom: 12px;
        margin-left: -20px;
        font-weight: 500;
        color: #979FB1;
        font-size: 16px;
        /* justify-content: space-between; */
    }

    .wn-sub-text2 {
        margin-bottom: 12px;
        color: #979FB1;
        font-size: 16px;
        font-weight: 500;
        margin-left: 0px;
        /* justify-content: space-between; */
    }
}