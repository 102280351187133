.ant-layout-footer {
    /* margin-top: 80px;
    padding-top: 50px; */
    overflow-x: hidden;
}

footer .footer-logo-desc-wrapper img {
    width: auto;
    height: 30px;
    margin-right: 5px;

}

footer .footer-logo-desc-wrapper h4 {
    font-size: 25px;
    color: #fff;
}

footer .footer-logo-desc-wrapper {
    margin-bottom: 20px;
}

footer .col-1 .footer-description {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    max-width: 300px;
    font-weight: 300;
}


footer .col-2 {
    padding-left: 30px
}

footer .footer-col-title {
    color: #fff;
    font-size: 22px;
    margin-bottom: 25px;
    text-transform: uppercase;
}

footer .menu-links {
    display: flex;
    flex-direction: column;
}

footer .link {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 15px;
    margin-left: 5px;
}

.footer-row-1 {
    position: relative;
}

footer .footer-row-1::after {
    content: '';
    width: 140%;
    height: 5px;
    left: -20%;
    right: 0;
    bottom: 0;
    background: rgb(123, 97, 255);
    background: linear-gradient(90deg, rgba(123, 97, 255, 1) 0%, rgba(9, 9, 121, 1) 50%, rgba(123, 97, 255, 1) 100%);
    position: absolute;
}

footer .footer-row-2 {
    margin-top: 30px;
}


footer .footer-social-links .link {
    margin-right: 10px;
}

footer .footer-social-links img {
    width: 32px;
}

footer .footer-row-2 .terms-policy .link {
    margin-right: 25px;
}

@media only screen and (max-width: 768px) {
    .ant-layout-footer {
        padding: 30px 15px;
    }

    footer .footer-row-2 .footer-logo-desc-wrapper {
        justify-content: center;
    }

    footer .footer-row-1 .footer-description {
        margin-left: auto !important;
        margin-right: auto !important;
        justify-content: center;
    }

    footer .footer-row-1 .col-2 {
        text-align: center !important;
        padding-left: unset;
        margin-top: 50px;
    }

    footer .footer-row-1 .col-3 {
        margin-top: 50px;
        text-align: center;
        margin-bottom: 20px;
    }
}