.player-insight-card-pc {
    margin: 5px;
    background: #0A0F1D;
    border: 3px solid rgba(0, 126, 255, .5294117647058824);
    /* border-radius: 22px !important; */
    /* border: none !important; */
    min-height: 400px;
    max-height: 400px;
}



.bottom-line {
    border-bottom: 1px solid #3B496A;
}

.players-card-headline {
    text-align: center;
}

.text-value-style {
    color: #00B0F2;
}

@media (max-width: 768px) {
    .player-insight-card-mobile {
        margin: 5px;
        background: #0A0F1D;
        border: 2px solid #4648d853;
        border-radius: 22px !important;
    }
}