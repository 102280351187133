.play-now {
    /* background: #0A0F1D !important; */
    border: none !important;
    /* margin-top: -25px;
    margin-bottom: -25px;
    margin-right: -24px;
    margin-left: -24px; */
    padding: 25px;
    /* border-left: 2px solid #4648d853 !important;
    border-right: 2px solid #4648d853 !important; */
    /* background: rgba(16, 24, 60, 0.66) !important; */
    background: rgb(16 24 60 / 0%) !important;
    /* box-shadow: rgba(0, 126, 255, 0.6) 0px 1px 1px, rgba(0, 75, 169, 0.48) 1px 5px !important; */
    /* border-radius: 10px; */
    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */
}

.claim-card-headline {
    text-align: center;
}

.claim-card-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-bottom: 4px; */
}

.sub-text {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
}

.sub-text2 {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 100;
    justify-content: space-between;
}

.button-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-card-ticket-no {
    /* background: #07084e; */
    /* min-height: 20px; */
    /* margin-top: 20px; */
    /* margin-left: 20px; */
}

.align-avatar-row {
    margin-left: 45px;
}

.list-card-ticket-edit {
    padding-top: 10px;
    padding-bottom: 15px;
    /* background: #07084e; */
    min-height: 310px;
    max-height: 310px;
}

.list-card-ticket-edit2 {
    padding-top: 10px;
    padding-bottom: 15px;
    /* background: #07084e; */
    min-height: 200px;
    max-height: 200px;
}

.ticket-checkbox {
    margin-left: 15px;
    margin-top: 10px;
    width: 30px;
}

.ticket-close {
    margin-right: 25px;
    margin-top: 8px;
    width: 30px;
}

.no-arrow-input .ant-input-number-handler-wrap {
    display: none !important;
}

.row-width {
    max-width: 331px;
    min-width: 331px;
}

.ticket-list-background-play-now {
    /* background-image: url(../../../images/ticket-bg.png); */
    margin-top: 5px;
    margin-bottom: 5px;
    width: 410px;
    height: 130px;
    /* margin-left: 19px; */
}

.avatar-align-center {
    margin-left: 10px;
}

.row-align-tickets-random {
    margin-top: 10px;
    /* margin-left: 25px; */
}

.number-edits-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.number-edits-message-close1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.top-align-prev {
    justify-content: space-between;
    align-items: center;
}

.number-edits-message-close2 {
    display: flex;
    justify-content: right;
    /* align-items: center; */
    /* margin-right: -7px; */
}

.buy-now {
    font-size: 18px;
    font-weight: 700;
}

.quantity-text {
    font-size: 16px;
    font-weight: 500;
}

.number-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .3);
    color: #ffffff;
}

.bottom-line {
    border-bottom: 1px solid #3b496a;
}

.m-button-size {
    max-width: 200px !important;
}

.draw-color-prev {
    margin-top: 0px;
    color: #979FB1;
}

.ticket-list-background-prev {
    /* background-image: url(../../../images/ticket-bg.png); */
    width: 425px;
    height: 130px;
    /* margin-left: 19px; */
}

@media (max-width: 768px) {
    .guide-alignment {
        margin-left: -18px;
    }

    .winning-draw-numbers-playnow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
        max-width: 304px;
    }

    .draw-color-prev {
        margin-top: -3px;
        color: #979FB1;
        font-size: 12px;
    }

    .ticket-checkbox {
        margin-left: 15px;
        margin-top: 19px;
        width: 30px;
    }

    .ticket-close {
        margin-right: 25px;
        margin-top: 13px;
        width: 30px;
    }

    .ticket-alignment-mobile-edit {
        margin-top: -20px;
    }

    .number-edits-message-close3 {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .number-edits-message-close2 {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .draw-color-edit {
        color: #979FB1;
        font-size: 11px;
        font-weight: 500;
        margin-top: 5px;
        margin-right: -23px;
    }

    .draw-color-edit1 {
        /* color: #979FB1; */
        font-size: 11px;
        font-weight: 500;
        margin-top: 5px;
    }

    /* .ticket-list-background-play-now {
        width: 250px !important;
        height: 130px !important;
    } */

    .align-choose-number {
        /* max-width: 180px; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        /* margin-left: 14px; */
        margin-bottom: 20px;
    }

    .ticket-list-background-play-now {
        /* background-image: url(../../../images/ticket-bg.png); */
        max-width: 270px;
        min-width: 270px;
        height: 120px !important;
        /* margin-left: -10px; */
    }

    .list-card-ticket-edit {
        max-height: 270px;
        padding-top: 25px;
        margin-bottom: -30px;
        min-height: 270px;
    }

    .align-avatar-row1 {
        margin-left: 27px;
    }

    .m-button-size {
        max-width: 200px !important;
    }
}

.error-messages {
    font-size: 12px;
    color: red;
}

.line-align {
    margin-top: -40px;
}