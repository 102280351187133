.jackpot-countdown-card {
    /* width: 480.77px; */
    height: 262px;
    background: rgba(16, 24, 60, 0.66) !important;
    border-top: 1px solid #00B0F2 !important;
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48) !important;
    border-radius: 16px;
    /* margin-top: 50px !important; */
    /* min-height: 280px; */
    /* max-height: 280px; */
    /* margin: 5px; */
    /* background: #0A0F1D; */
    /* border: 3px solid #4648d853; */
    /* border-radius: 22px !important; */
    /* border: none !important; */

}

.jackpot-countdown-headline {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.ticket-price-botomline {
    color: #718096;
    font-weight: 500;
    /* font-size: 16px; */
    line-height: 24px;

}

.wallet-connect-button {
    min-width: 125px;
}

.button-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.playnow-modal {
    margin: 5px;
    background: #0A0F1D;
    border: 2px solid #4648D8;
    border-radius: 0 !important;
}

@media (max-width:600px) {
    .jackpot-countdown-card {
        /* margin-top: 25px !important; */
    }
}