.divider {
    width: 120px;
    height: 5px;
    background: #FFFFFF;
    margin-bottom: 30px;
}

.text-value-style1 {
    color: #ffffff;
    font-weight: 800;
    font-size: 42px;
}

.text-value-style2 {
    color: #00B0F2;
    font-weight: 800;
    font-size: 48px;
}

.not-found-card {
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
    border-radius: 16px;
    /* width: 480.77px; */
    height: 262px;
    background: radial-gradient(100.05% 336.88% at 100% 50%, rgba(44, 91, 223, 0.4356) 0%, rgba(16, 24, 60, 0.4356) 60.42%) !important;
    border-top: 1px solid #00B0F2 !important;
}

@media (max-width:600px) {
    .text-value-style1 {
        color: #ffffff;
        font-weight: 800;
        font-size: 26px;
    }

    .text-value-style2 {
        color: #00B0F2;
        font-weight: 800;
        font-size: 26px;
    }

    .not-found-card {
        box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
        border-radius: 16px;
        height: 226px;
        background: radial-gradient(100.05% 336.88% at 100% 50%, rgba(44, 91, 223, 0.4356) 0%, rgba(16, 24, 60, 0.4356) 60.42%) !important;
        border-top: 1px solid #00B0F2 !important;
    }
}