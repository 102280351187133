.results-table-card {
    /* margin: 5px; */
    /* background: #0A0F1D; */
    /* border: 3px solid rgba(0, 126, 255, .5294117647058824); */
    /* border: 2px solid #4648d853; */
    /* border-radius: 22px !important; */
    /* border: none !important; */
    height: 330px !important;
    background: rgba(16, 24, 60, 0.66);
    border-top: 1px solid #00B0F2 !important;
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
    border-radius: 16px;
}

.table-align-results-rule {
    padding-right: -50px !important;

}

/* .ant-table tfoot>tr>td,
.ant-table tfoot>tr>th,
.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
    position: relative;
    padding: 16px;
    text-align: center !important;
    overflow-wrap: break-word;
} */

@media (max-width: 768px) {
    .results-table-card {
        min-height: 315px !important;
        max-height: 315px !important;
    }

    .bottom-line-align {
        margin-bottom: 10px;
    }
}