.list-card {
    padding-top: 20px;
    background: rgba(16, 24, 60, 0.66);
    min-height: 300px;
}

.your-ticket-card {
    background: rgba(16, 24, 60, 0.66);
    border-top: 1px solid #00B0F2 !important;
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
    border-radius: 16px;
    min-height: 400px;
    max-height: 400px;
}

.claim-card-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-bottom: 4px; */
}

.your-tickets-headline {
    font-weight: 700;
    font-size: 16px;
    /* line-height: 24px; */
}

.allTicket-align {
    width: 40px !important;
    border-radius: 5px;
    margin-left: 5px;
}

.allTicket-align2 {
    border-radius: 5px;
    margin-left: 5px;
    margin-top: -5px;
}

.your-tickets-headline2 {
    /* margin-right: 20px; */
    font-weight: 700;
    font-size: 16px;
    /* line-height: 24px; */
}

.custom-ol {
    margin: 10px;
}

.list-card-ticket-no {
    background: rgba(16, 24, 60, 0.66);
    min-height: 20px;
    margin-top: 20px;
    margin-left: 20px;
}

.list-card-ticket {
    padding-top: 10px;
    padding-bottom: 15px;
    /* background: #07084e; */
    min-height: 180px;
    max-height: 180px;
}

.align-your-tickets {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 500;
}

/* .row-align-tickets {
    margin-top: 10px;
} */

.all-ticket-border {
    border-radius: 22px;
    background-color: #9d9da438;
    padding: 8px;
}

.center-items-set {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-icon-set {
    height: 120px;
    width: 120px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 1px rgba(31, 110, 243, 0.6);
    border-radius: 20px;
}

.winning-draw-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.center-pragraph-prize {
    text-align: center;
    max-width: 250px;
    min-width: 200px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.new-head {
    min-height: 48px;
    /* margin-bottom: -1px; */
    padding: 0 24px;
    color: hsl(0, 0%, 100%, 0.86);
    /* font-weight: 500; */
    /* font-size: 16px; */
    /* border-bottom: none !important; */
    /* box-shadow: rgba(0, 126, 255, 0.6) 0px 1px 1px, rgb(0, 0, 0) 1px 5px; */
    background: rgba(16, 24, 60, 0.66) !important;
    border-top: 1px solid #00B0F2 !important;
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48) !important;
    border-radius: 16px;
}

.ant-card-head-draw {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: hsl(0, 0%, 100%, 0.86);
    font-weight: 500;
    font-size: 16px;
    background: 0 0;
    border-bottom: none !important;
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #5dabff;
    box-shadow: rgba(0, 126, 255, 0.6) 0px 1px 1px, rgb(0, 0, 0) 1px 5px;
}

.ticket-list-background {
    /* background-image: url('../../../images/ticket-bg.png'); */
    /* width: 425px; */
    height: 130px;
    /* margin-left: 19px; */
}

.draw-color {
    color: #979FB1;
    font-size: 16px;
    font-weight: 500;
}

.separate-botom-line {
    border: 1px dashed #3B496A;
    width: 370px;
}

@media (max-width: 768px) {

    .your-ticket-card {
        min-height: 291px;
        max-height: 291px;
    }

    .ticket-list-background {
        /* background-image: url('../../../images/ticket-bg.png'); */
        max-width: 285px !important;
        height: 120px !important;

        /* margin-left: 19px; */

    }

    .ticket-alignment-mobile {
        margin-top: -30px;
    }

    .mobile-view-only {
        padding-right: -12px;
    }

    .separate-botom-line {
        border: 1px dashed #3B496A;
        width: 220px;
    }

    .winning-draw-numbers {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
    }

    .draw-color {
        color: #979FB1;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
    }

    .center-items-set {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }

    .center-pragraph-prize {
        text-align: center;
        max-width: 250px;
        min-width: 250px;
        font-size: 14px;
    }

    .card-icon-set {
        height: 100px;
        width: 100px;
        justify-content: center;
        margin-top: 0px !important;
    }

    .align-your-tickets {
        margin-top: 50px;
        font-size: 14px;
        margin-left: 5px;
        margin-right: 5px;
    }
}