.jackpot-value-card {
    /* min-height: 280px; */
    /* max-height: 280px; */
    /* margin: 5px; */
    /* background: #0A0F1D; */
    /* border: 3px solid rgba(0, 126, 255, .5294117647058824); */
    /* border-radius: 22px !important; */
    /* border: none !important; */
    /* padding: 55px 0px 55px 20px; */
    /* shadow */

    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
    border-radius: 16px;
    /* width: 480.77px; */
    height: 262px;
    background: radial-gradient(100.05% 336.88% at 100% 50%, rgba(44, 91, 223, 0.4356) 0%, rgba(16, 24, 60, 0.4356) 60.42%) !important;
    border-top: 1px solid #00B0F2 !important;
    /* margin-top: 50px !important; */
}

.jackpot-headline {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px;
    display: flex;
    color: #00B0F2 !important;
}

.jackPotValue-style {
    font-size: 36px !important;
    font-weight: 600;
}

.card-icon {
    /* height: 200px;
    width: 200px;
    margin-top: 5px; */
    /* margin-top: 3px; */
    /* margin-left: -82px; */
    /* border-radius: 50%; */
    /* position: absolute; */
    margin-top: 20px;
    right: 0px;
    position: absolute;
    width: 200px;
    height: 171px;
    /* left: 497px; */
    /* top: 209px; */
}

.text-align-middle {
    vertical-align: middle;
}

@media (max-width: 768px) {
    .jackpot-value-card {
        height: 188px;
        /* margin-top: 50px !important; */
    }

    .card-icon {
        height: 110px;
        width: 110px;
        margin-top: 20px;
        /* margin-top: 3px; */
        right: 0px;
    }

    /* .jackpot-headline {
        font-weight: 500;
        font-size: 16px !important;
        line-height: 24px;
        display: flex;
        color: #00B0F2 !important;
    } */
    .jackPotValue-style {
        font-size: 24px !important;
        font-weight: 600;
    }
}


.text-value-style {
    text-shadow: 0px 0px 10px rgba(0, 189, 242, 0.5) !important;
    /* position: absolute; */
    left: 0px;
    top: 34px;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 54px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;

    color: #D9D9D9 !important;
}

.subtext-style {
    font-size: 15px;
    font-weight: 700;
    color: rgb(135, 145, 165);
}

.subtext2-style {
    font-size: 11px;
    font-weight: 400;
    color: rgb(135, 145, 165);
    margin-top: -15px;
}

.subtext-plus-style {
    margin-top: 8px;
    margin-left: -5px;
    size: 16px;
    font-weight: 700;
    color: rgb(135, 145, 165);
}