.claim-card {
    height: 330px;
    background: rgba(16, 24, 60, 0.66);
    border-top: 1px solid #00B0F2 !important;
    box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
    border-radius: 16px;
}

.ticket-bottom {
    margin-bottom: 30px;
}

.claim-card-headline {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.claim-card-downline {
    text-align: center;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
}

.claim-card-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-bottom: 4px; */
}

.claim-card-content1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.claim-card-button1 {
    min-width: 140px;
}

.sub-text {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    margin-top: 2px;
    justify-content: space-between;
}

.sub-text2 {
    margin-bottom: 2px;
    color: #979FB1;
    font-size: 16px;
    margin-top: 2px;
    font-weight: 500;
    justify-content: space-between;
}

/* 
.ticket-alignment {
    min-height: 270px;
    max-height: 270px;
} */

.button-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-line {
    border-bottom: 1px solid #3B496A;
}

@media (max-width: 768px) {
    .claim-card {
        height: 340px;
        background: rgba(16, 24, 60, 0.66);
        border-top: 1px solid #00B0F2 !important;
        box-shadow: 0px 1px 1px rgba(0, 126, 255, 0.6), 1px 5px 0px rgba(0, 75, 169, 0.48);
        border-radius: 16px;
    }

    .ticket-bottom {
        margin-bottom: 3px;
    }

    .claim-card-content1 {
        display: flex;
        align-items: flex-start;
        justify-content: center !important;
        max-width: 140px !important;
        min-width: 140px !important;
    }

    .claim-card-button2 {
        display: flex;
        justify-content: center !important;
        /* margin-left: 20px; */
    }

    .margin-b {
        margin-left: -12px;
    }
}