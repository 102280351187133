.counter {
    display: flex;
    gap: 0.2rem;

    .counter-item {
        width: 100px;
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border-radius: 0.25rem; */
        /* background: var(--blue); */
    }

    .label {
        /* font-size: 0.6rem; */
        /* color: var(--blue-dark); */
        /* width: 100%; */
        /* text-transform: uppercase; */
        /* font-weight: 700; */
        text-align: center;
        margin-top: 0.1rem;
        background: var(--white);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 150% */
        display: flex;
        align-items: center;
        color: #718096 !important;
    }

    .value {
        display: inline-block;
        width: 40px;
        height: 50px;
        padding-top: 10px;
        margin: 2px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        background: #141E31;
        border: 1px solid #3B496A;
        border-radius: 6px;
        box-sizing: border-box;
    }


    /* @media (max-width: 768px) {
        span.value {
            font-size: 2.5rem;
        } */


    /* } */

    @media (max-width: 768px) {
        .counter-item {
            width: 70px;
            /* display: flex; */
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .value {
            display: inline-block;
            width: 30px;
            height: 40px;
            padding-top: 8px;
            margin: 2px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            background: #141E31;
            border: 1px solid #3B496A;
            border-radius: 6px;
            box-sizing: border-box;
        }

        .label {
            /* font-size: 0.6rem; */
            /* color: var(--blue-dark); */
            /* width: 100%; */
            /* text-transform: uppercase; */
            /* font-weight: 700; */
            text-align: center;
            margin-top: 0.1rem;
            background: var(--white);
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 150% */
            display: flex;
            align-items: center;
            color: #718096 !important;
        }
    }
}

@media (max-width: 768px) {
    .counter-item {
        width: 80px;
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
        background: var(--blue);
    }

    .value {
        width: 50%;
        padding: 4px;
        font-size: 15px;
        color: #ffffff;
        text-align: center;
        border-radius: 22px;
        border: 1px solid #4648d853;
    }

    .label1 {
        font-size: 13px !important;
        color: #718096;
        width: 100%;
        /* text-transform: uppercase; */
        font-weight: 500;
        text-align: center;
        margin-top: 0.1rem;
        background: var(--white);
    }

    .label2 {
        font-size: 13px !important;
        color: #718096;
        width: 100%;
        /* text-transform: uppercase; */
        font-weight: 500;
        text-align: center;
        margin-top: 0.1rem;
        background: var(--white);
    }

    .label3 {
        font-size: 13px !important;
        color: #718096;
        width: 100%;
        /* text-transform: uppercase; */
        font-weight: 500;
        text-align: center;
        margin-top: 0.1rem;
        background: var(--white);
    }
}