.bg-blur-nav {
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1 !important;
    /* this line added to show side menu drawer fully */
}

.navbarlink {
    color: #ffffff;
    margin-right: 20px;
    font-weight: 400 !important;
    font-size: 16px;
    padding: 5px 0px;
    /* width: 0;
    border-bottom: 2px solid #fff !important;
    transition: .7s; */
}

.navbarlink:hover {
    color: #4648D8;
    padding: 5px 0px;
    font-weight: 400 !important;
    font-size: 16px;
    transition: 0.2s;
    width: 90%;
    border-bottom: 2px solid #4648D8 !important;
    transition: .2s;
}

.list-navbarlink {
    margin-right: 20px;
}

.list-navbarlink:hover {
    color: #4648D8;
    /* padding: 5px 0px;
    font-weight: 400 !important;
    font-size: 16px;
    transition: 0.2s;
    width: 90%; */
    /* border-bottom: 2px solid #4648D8 !important; */
    /* transition: .2s; */
}

.centered-navbar ul {
    margin-bottom: 0;
}

.navbar-brand div h4 {
    margin-bottom: 0;
}

.logo-text {
    margin-left: 4px;
}

@media only screen and (max-width: 768px) {
    .hide-on-mobile {
        display: none !important;
    }

    .navbar-brand {
        align-items: center;
    }

    .navbar-brand div h4 {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 769px) {
    .hide-on-pc {
        display: none !important;
    }
}

.wallet-connect-align {
    margin-right: -25px;
}

.wallet-connect-align2 {
    margin-right: -35px;
}